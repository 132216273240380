// import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
// import { I18nextProvider } from "react-i18next";
// import "antd/dist/antd.css";

// import Router from "./router";
// import i18n from "./translation";

const App = () => (
  // <BrowserRouter>
  //   <I18nextProvider i18n={i18n}>
  //     <Router />
  //   </I18nextProvider>
  // </BrowserRouter>
  <>
    <h1>YOU'RE RICKF*KINGROLLED</h1>
    <iframe width="2560" height="1440" src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&controls=0" allow="autoplay" title="YouTube video player"></iframe>
  </>
);

ReactDOM.render(<App />, document.getElementById("root"));
